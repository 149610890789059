import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { getParent, dispatchForCode } from '../../js/helper';

class Navigation {
    constructor (element, options) {
        const settings = {
            initAttr: 'data-nav',
            itemsAttr: 'items',
            toggleAttr: 'toggle',
            rootToggleAttr: 'root-toggle',
            scrollerAttr: 'scroller',
            headerAttr: '[data-header="root"]',
            searchInitAttr: 'data-search',
            searchToggleAttr: 'root-toggle',
            categoryAttr: 'category',
            eventScroller: null,
            actionsAttr: 'actions',
            resizeHandler: null
        };

        this.settings = Object.assign({}, settings, options);

        this.$navigation = element;
        this.$navigationScroller = this.$navigation.querySelector('[' + this.settings.initAttr + '="' + this.settings.scrollerAttr + '"]');
        this.$navigationToggles = this.$navigation.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.toggleAttr + '"]');
        this.$rootToggle = document.querySelector('[' + this.settings.initAttr + '="' + this.settings.rootToggleAttr + '"]');
        this.$searchToggle = document.querySelector('[' + this.settings.searchInitAttr + '="' + this.settings.searchToggleAttr + '"]');
        this.$categories = this.$navigation.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.categoryAttr + '"]');
        this.$actions = [].slice.call(this.$navigation.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.actionsAttr + '"]'));
        this.$header = document.querySelector(this.settings.headerAttr);
        this.navigationOpen = false;
        this.$activeNavToggle = null;
        this.$loginToggle = document.querySelector('[id="sub-nav_toggle-login"]');
        this.$loginMainToggle = document.querySelector('[id="main-nav__login-toggle"]');
        this.$loginRegisterInfo = document.querySelector('[id="login-nav-register-info"]');
        this.initialize();
    }

    initialize () {
        if (this.$loginMainToggle) {
            this.$actions.push(this.$loginMainToggle);
        }

        this.setEvents();

        if (this.$categories.length > 0) {
            window.setTimeout(() => {
                this.setCategories(this.$categories);
            }, 250);
        }

        this.breakpoint = window.matchMedia('(min-width:1024px)');
    }

    setEvents () {
        document.addEventListener('keyup', (e) => {
            if (this.$activeNavToggle) {
                dispatchForCode(e, (key) => {
                    if (key === 'Escape') {
                        this.$activeNavToggle.checked = false;
                    }
                });
            }
        });
        this.$navigation.addEventListener('change', (e) => {
            const $changedToggle = e.target;

            if ($changedToggle.getAttribute(this.settings.initAttr) === this.settings.toggleAttr) {
                const $parent = getParent($changedToggle, '[' + this.settings.initAttr + '="' + this.settings.itemsAttr + '"]');

                if ($parent) {
                    const $items = $parent.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.toggleAttr + '"]:checked');
                    for (let i = 0; i < $items.length; i++) {
                        const $item = $items[i];
                        if ($item !== $changedToggle) {
                            $item.checked = false;
                        }
                    }
                }

                if ($changedToggle.checked === true) {
                    if (this.$header) {
                        this.$header.classList.add('show--always');
                    }
                    if (this.$searchToggle) {
                        this.$searchToggle.checked = false;
                    }
                    this.navigationOpen = true;
                    this.$activeNavToggle = $changedToggle;
                    this.closeAllActionItems();
                } else {
                    if (this.$header) {
                        this.$header.classList.remove('show--always');
                    }
                    this.navigationOpen = false;
                    this.$activeNavToggle = null;
                }
            }
        });

        if (this.$loginToggle) {
            this.$loginToggle.addEventListener('change', () => {
                if (window.innerWidth < 1024) {
                    if (this.$loginToggle.checked) {
                        window.location.href = this.$loginToggle.getAttribute('data-href');
                    }
                }
            });
        }

        if (this.$loginMainToggle) {
            this.$loginMainToggle.addEventListener('change', () => {
                if (this.$loginMainToggle.checked === false) {
                    this.$loginRegisterInfo.checked = false;
                }
            });
        }

        if (this.$rootToggle) {
            this.$rootToggle.addEventListener('change', () => {
                if (this.$rootToggle.checked === true) {
                    // if search is open, close search
                    if (this.$searchToggle && this.$searchToggle.checked === true) {
                        this.$searchToggle.checked = false;
                        const toggleEvent = new Event('change');
                        this.$searchToggle.dispatchEvent(toggleEvent);
                    }
                    if (this.$header) {
                        this.$header.classList.add('show--always');
                    }
                    if (!window.Modernizr.csshover) {
                        disableBodyScroll(this.$navigationScroller);
                    }
                } else {
                    if (this.$header) {
                        this.$header.classList.remove('show--always');
                    }
                    if (!window.Modernizr.csshover) {
                        enableBodyScroll(this.$navigationScroller);
                    }

                    this.closeAllNavigationItems();
                }
            });
        }

        // close navigation if anchor link is clicked in navigation
        if (this.$categories) {
            for (let i = 0; i < this.$categories.length; i++) {
                const $linkItem = this.$categories[i];
                const linkHref = String($linkItem.getAttribute('href'));
                if (linkHref !== '') {
                    if (linkHref.indexOf('#') > -1) {
                        $linkItem.addEventListener('click', () => {
                            this.closeAllNavigationItems();
                        });
                    }
                }
            }
        }

        // close navigation if anchor link is clicked in mobile navigation
        const $mobileShowAllLinks = this.$navigation.querySelectorAll('.sub-nav__list-item.has--show-all a');
        const $navigationToggle = this.$navigation.closest('.siteheader__inner').querySelector('.siteheader__navigation-toggle');
        if ($mobileShowAllLinks.length > 0) {
            for (let i = 0; i < $mobileShowAllLinks.length; i++) {
                const $linkItem = $mobileShowAllLinks[i];
                const linkHref = String($linkItem.getAttribute('href'));
                if (linkHref !== '') {
                    if (linkHref.indexOf('#') > -1) {
                        $linkItem.addEventListener('click', () => {
                            if ($navigationToggle) {
                                $navigationToggle.click();
                            }
                        });
                    }
                }
            }
        }

        this.$navigation.addEventListener('click', (e) => {
            if (this.navigationOpen === true && this.breakpoint.matches === true) {
                if (e.target.classList.contains('sub-nav') || e.target.classList.contains('main-nav__list') || e.target.classList.contains('main-lang-nav')) {
                    this.$activeNavToggle.checked = false;
                }
            }
        });
        /*
        document.addEventListener('click', (e) => {
            const $clickedElement = e.target;

            if (this.navigationOpen === true && this.breakpoint.matches === true && $clickedElement.tagName.toLowerCase() !== 'label') {
                const $parent = getParent($clickedElement, '.sub-nav__inner');

                if ($parent === null) {
                    this.closeAllNavigationItems();
                }
            }
        });
        */

        const resizeHandler = this.settings.resizeHandler;
        if (resizeHandler !== null) {
            resizeHandler.customFunctions.push(() => {
                this.breakpoint = window.matchMedia('(min-width:1024px)');
                if (this.$categories.length > 0) {
                    this.setCategories(this.$categories);
                }
            });
        }

        this.$actions.forEach((action) => {
            action.addEventListener('change', () => {
                this.closeAllNavigationItems();
                this.closeAllActionItems(action);
            });
        });
    }

    closeAllNavigationItems () {
        for (let i = 0; i < this.$navigationToggles.length; i++) {
            this.$navigationToggles[i].checked = false;
        }
        this.navigationOpen = false;
    }

    closeAllActionItems (stayOpenAction) {
        if (this.$actions) {
            this.$actions.forEach((action) => {
                if (stayOpenAction) {
                    if (action !== stayOpenAction) {
                        action.checked = false;
                    }
                } else {
                    action.checked = false;
                }
            });
        }
    }

    setCategories ($categories) {
        const spreadCategoriesInArray = () => {
            const spreadArray = [];
            let topPosition = 0;
            let arrayIndex = -1;
            for (let i = 0; i < $categories.length; i++) {
                const $category = $categories[i];
                if ($category.offsetHeight > 0) {
                    if ($category.offsetTop !== topPosition) {
                        arrayIndex++;
                        topPosition = $category.offsetTop;
                        spreadArray[arrayIndex] = [];
                    }
                    spreadArray[arrayIndex].push($category);
                }
            }
            return spreadArray;
        };

        const calculateHeight = (categories) => {
            let maxHeight = 0;

            for (let i = 0; i < categories.length; i++) {
                const $category = categories[i];
                $category.style.minHeight = 0;
                maxHeight = $category.offsetHeight > maxHeight ? $category.offsetHeight : maxHeight;

                if (i === categories.length - 1) {
                    for (let j = 0; j < categories.length; j++) {
                        const $category = categories[j];
                        $category.style.minHeight = maxHeight + 'px';
                    }
                }
            }
        };

        const categoriesArray = spreadCategoriesInArray();

        for (let i = 0; i < categoriesArray.length; i++) {
            const categoryArray = categoriesArray[i];
            calculateHeight(categoryArray);
        }
    }
}

export default Navigation;
