const createElementFromHTML = (htmlString) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    if (div.children.length > 1) {
        const itemsArray = [];
        Array.prototype.push.apply(itemsArray, div.children);

        return itemsArray;
    } else {
        return div.firstChild;
    }
};

const getEntries = (obj) => {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i); // preallocate the Array
    while (i--) {
        resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }
    return resArray;
};

const dispatchForCode = (event, callback) => {
    let code;

    if (event.key !== undefined) {
        code = event.key;
    } else if (event.keyIdentifier !== undefined) {
        code = event.keyIdentifier;
    } else if (event.keyCode !== undefined) {
        code = event.keyCode;
    }

    if (typeof callback === 'function') {
        callback(code);
    }
};

const getParent = (elem, selector) => {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector || Element.prototype.oMatchesSelector || Element.prototype.webkitMatchesSelector || function (s) {
            const matches = (this.document || this.ownerDocument).querySelectorAll(s);
            let i = matches.length;
            while (--i >= 0 && matches.item(i) !== this) { console.log('getting parent'); }
            return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
    }
    return null;
};

const getOffset = (el) => {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft,
        bottom: rect.top + scrollTop + rect.height
    };
};

const loadImage = (img, loadHandler, errorHandler) => {
    const helperImg = new Image();
    if (loadHandler) {
        helperImg.addEventListener('load', () => {
            loadHandler(img);
        }, false);
    }
    if (errorHandler) {
        helperImg.addEventListener('error', () => {
            errorHandler(img, false);
        }, false);

        helperImg.addEventListener('abort', () => {
            errorHandler(img, true);
        }, false);
    }
    helperImg.src = img.src;
};

const loadScript = (source, beforeEl, async = true, defer = true) => {
    return new Promise((resolve, reject) => {
        let script = document.createElement('script');
        const prior = beforeEl || document.getElementsByTagName('script')[0];

        script.async = async;
        script.defer = defer;

        function onloadHander (_, isAbort) {
            if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
                script.onload = null;
                script.onreadystatechange = null;
                script = undefined;

                if (isAbort) {
                    const error = new Error('something went wrong');
                    reject(error);
                } else {
                    resolve(script);
                }
            }
        }

        script.onload = onloadHander;
        script.onreadystatechange = onloadHander;

        script.src = source;
        prior.parentNode.insertBefore(script, prior);
    });
};

const loadYoutubeApi = () => {
    if (typeof YT === 'undefined') {
        const tag = document.createElement('script');
        tag.src = '//www.youtube.com/iframe_api';

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
};

const loadYoutubeApiPromise = () => {
    return new Promise((resolve, reject) => {
        if (typeof window.YT === 'undefined' && !window.youtubeIsLoading) {
            window.youtubeIsLoading = true;
            const link = document.createElement('script');
            link.src = '//www.youtube.com/iframe_api';

            link.onload = () => resolve(link);
            link.onerror = () => reject(new Error('YouTube can not be loaded'));
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(link, firstScriptTag);
        } else {
            resolve(true);
        }
    });
};

const smoothScrollTo = (endX, endY, duration = 400, callback) => {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();

    // Easing function
    const easeInOutQuart = (time, from, distance, duration) => {
        if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
        return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = window.setInterval(() => {
        const time = new Date().getTime() - startTime;
        const newX = easeInOutQuart(time, startX, distanceX, duration);
        const newY = easeInOutQuart(time, startY, distanceY, duration);

        if (time >= duration) {
            window.clearInterval(timer);
            if (typeof callback === 'function') {
                callback();
            }
        }
        window.scrollTo(newX, newY);
    }, 1000 / 60); // 60 fps
};

const containerSmoothScrollTo = ($container, endX, endY, duration) => {
    const startX = $container.scrollLeft;
    const startY = $container.scrollTop;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();

    duration = typeof duration !== 'undefined' ? duration : 400;

    // Easing function
    const easeInOutQuart = (time, from, distance, duration) => {
        if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
        return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = window.setInterval(() => {
        const time = new Date().getTime() - startTime;
        const newX = easeInOutQuart(time, startX, distanceX, duration);
        const newY = easeInOutQuart(time, startY, distanceY, duration);

        if (time >= duration) {
            window.clearInterval(timer);
        }
        $container.scrollLeft = newX;
        $container.scrollTop = newY;
    }, 1000 / 60); // 60 fps
};

const tryParseJSON = (jsonString) => {
    try {
        const o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === 'object') {
            return o;
        }
    } catch (e) {
        console.log(e);
    }

    return false;
};

const uniqueID = () => {
    const chr4 = () => {
        return Math.random().toString(16).slice(-4);
    };

    return chr4() + chr4() +
    '-' + chr4() +
    '-' + chr4() +
    '-' + chr4() +
    '-' + chr4() + chr4() + chr4();
};

const serializeForm = (form) => {
    let field;
    const s = [];

    if (typeof form === 'object' && form.nodeName === 'FORM') {
        for (let i = 0; i < form.elements.length; i++) {
            field = form.elements[i];

            if (field.name && !field.disabled && field.type !== 'file' && field.type !== 'reset' && field.type !== 'submit' && field.type !== 'button' && field.name !== 'query') {
                if (field.type === 'select-multiple') {
                    for (let j = form.elements[i].options.length - 1; j >= 0; j--) {
                        if (field.options[j].selected) {
                            s[s.length] = encodeURIComponent(field.name) + '=' + encodeURIComponent(field.options[j].value);
                        }
                    }
                } else {
                    if ((field.type === 'checkbox') && field.checked) {
                        s[s.length] = encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value);
                    } else {
                        if (field.type === 'radio' && field.checked) {
                            s[s.length] = encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value);
                        } else {
                            if (field.type !== 'radio' && field.type !== 'checkbox') {
                                s[s.length] = encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value);
                            }
                        }
                    }
                }
            }
        }
    }
    return s.join('&').replace(/%20/g, '+');
};

export {
    createElementFromHTML,
    getEntries,
    dispatchForCode,
    getParent,
    getOffset,
    loadImage,
    loadScript,
    loadYoutubeApi,
    loadYoutubeApiPromise,
    smoothScrollTo,
    containerSmoothScrollTo,
    tryParseJSON,
    uniqueID,
    serializeForm
};
