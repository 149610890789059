import Selectr from 'Selectr';

class SelectBox {
    constructor (element, options) {
        const settings = {
            initAttr: 'data-select',
            searchAttr: 'search',
            singleAttr: 'single',
            taggableAttr: 'taggable'
        };

        this.$select = element;

        this.settings = Object.assign({}, settings, options);

        this.initialize();
    }

    initialize () {
        this.buildCustomSelect();
    }

    buildCustomSelect () {
        let searchable = false;
        const customClass = null;

        if (this.$select.getAttribute(this.settings.initAttr + '-' + this.settings.searchAttr) !== null) {
            searchable = true;
        }

        Object.defineProperty(Selectr.prototype, 'mobileDevice', {
            get () { return false; },
            set () {},
            enumerable: true,
            configurable: true
        });

        this.selectbox = new Selectr(this.$select, {
            allowDeselect: false,
            customClass: customClass,
            defaultSelected: false,
            searchable: searchable,
            clearable: false,
            placeholder: this.$select.getAttribute('placeholder'),
            disabled: this.$select.disabled
        });

        this.selectbox.on('selectr.init', () => {
            if (searchable === true) {
                this.selectbox.container.classList.add('has--search');
                this.selectbox.input.type = 'text';
                this.selectbox.input.setAttribute('placeholder', this.$select.getAttribute('placeholder'));
            }
        });
    }
}

export { SelectBox };
