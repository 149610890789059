class Footer {
    constructor (element, options) {
        const settings = {
            initAttr: 'data-footer',
            categoryAttr: 'category',
            resizeHandler: null
        };

        this.settings = Object.assign({}, settings, options);

        this.$footer = element;
        this.$categories = this.$footer.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.categoryAttr + '"]');

        this.initialize();
    }

    initialize () {
        this.setEvents();

        if (this.$categories.length > 0) {
            window.setTimeout(() => {
                this.setCategories();
            }, 250);
        }
    }

    setEvents () {
        const resizeHandler = this.settings.resizeHandler;
        if (resizeHandler) {
            resizeHandler.customFunctions.push(() => {
                if (this.$categories.length > 0) {
                    this.setCategories();
                }
            });
        }
    }

    setCategories () {
        const spreadCategoriesInArray = () => {
            const spreadArray = [];
            let topPosition = 0;
            let arrayIndex = -1;
            for (let i = 0; i < this.$categories.length; i++) {
                const $category = this.$categories[i];
                if ($category.offsetTop !== topPosition) {
                    arrayIndex++;
                    topPosition = $category.offsetTop;
                    spreadArray[arrayIndex] = [];
                }
                spreadArray[arrayIndex].push($category);
            }
            return spreadArray;
        };

        const calculateHeight = (categories) => {
            let maxHeight = 0;

            for (let i = 0; i < categories.length; i++) {
                const $category = categories[i];
                $category.style.minHeight = 0;
                maxHeight = $category.offsetHeight > maxHeight ? $category.offsetHeight : maxHeight;

                if (i === categories.length - 1) {
                    for (let j = 0; j < categories.length; j++) {
                        const $category = categories[j];
                        $category.style.minHeight = maxHeight + 'px';
                    }
                }
            }
        };

        const categoriesArray = spreadCategoriesInArray();

        for (let i = 0; i < categoriesArray.length; i++) {
            const categoryArray = categoriesArray[i];
            calculateHeight(categoryArray);
        }
    }
}

export default Footer;
