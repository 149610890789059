/**
 * usage
 * import { ResizeHandler } from './utilities/js/resize-handler';
 *
 * const resizeHandler = new ResizeHandler();
 * resizeHandler.init();
 *
 * resizeHandler.customFunctions.push(() => { // do stuff });
 */

// TODO yarn add lodash.debounce and remove this line
import debounce from 'lodash.debounce';

class ResizeHandler {
    constructor () {
        // this object can be extended if more breakpoint sets are needed
        this.breakpointSets = {
            design: {
                designSmall: {
                    min: 0,
                    max: 767
                },
                designMedium: {
                    min: 768,
                    max: 1023
                },
                designLarge: {
                    min: 1024,
                    max: 9999
                }
            },
            navigation: {
                navigationSmall: {
                    min: 0,
                    max: 1023
                },
                navigationLarge: {
                    min: 1024,
                    max: 9999
                }
            }
        };

        // store active breakpoint to prevent dispatching event, although
        // no breakpoints where changed
        this.activeBreakpoints = {};

        // functions added from other modules which are executed on resize
        this.customFunctions = [];
    }

    init () {
        this.bindEvents();
        this.checkBreakpoints();
    }

    bindEvents () {
        window.addEventListener('resize', debounce(() => {
            for (let i = 0; i < this.customFunctions.length; i++) {
                const customFunction = this.customFunctions[i];

                if (typeof customFunction === 'function') {
                    customFunction();
                }
            }
            this.checkBreakpoints();
        }, 100));
    }

    checkBreakpoints () {
        const width = window.innerWidth;

        // iterate through different breakpoint sets
        Object.keys(this.breakpointSets).forEach(setName => {
            // iterate through each breakpoint of current set
            Object.keys(this.breakpointSets[setName]).forEach(breakpointName => {
                const set = this.breakpointSets[setName];

                if (width >= set[breakpointName].min && width <= set[breakpointName].max) {
                    // check if key is defined in activeBreakpoints object
                    // add when needed
                    if (!Object.prototype.hasOwnProperty.call(this.activeBreakpoints, setName)) {
                        this.activeBreakpoints[setName] = '';
                    }

                    if (this.activeBreakpoints[setName] !== breakpointName) {
                        this.activeBreakpoints[setName] = breakpointName;
                        this.fireCustomEvent(breakpointName);
                    }
                }
            });
        });
    }

    fireCustomEvent (eventName) {
        let event;

        if (typeof Event === 'function') {
            event = new Event(eventName);
        } else {
            // IE 11 fallback
            event = document.createEvent('Event');
            event.initEvent(eventName, true, true);
        }

        document.dispatchEvent(event);
    }
}

export { ResizeHandler };
