class Text {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-text'
        };

        this.settings = Object.assign({}, defaults, options);

        this.$text = element;

        this.initialize();
    }

    initialize () {
        const textString = this.$text.innerHTML;
        const textArray = textString.split('<br>');

        this.$text.innerHTML = '';

        for (let i = 0; i < textArray.length; i++) {
            const textPart = textArray[i];
            const $snippet = document.createElement('span');
            const $snippetInner = document.createElement('span');

            $snippetInner.innerHTML = textPart;
            $snippet.appendChild($snippetInner);

            if (i > 0) {
                const $linebreak = document.createElement('b');
                const $linebreakInner = document.createElement('br');

                $linebreak.appendChild($linebreakInner);
                this.$text.appendChild($linebreak);
            }

            this.$text.appendChild($snippet);
        }
        // this.$text.classList.add('is--animated');
    }
}

export default Text;
